<template>
  <div>
    <div class="mid_boxs" style="width: 100%">
      <div class="mid_box_left_">
        <div class="mid_box_left_bt_top">
          <span>公司部门列表</span>
        </div>
        <div class="mid_box_left_bt">
          <el-tree
            :data="companyData"
            :props="defaultProps"
            accordion
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
      </div>
      <div class="mid_box_right_">
        <div
          class="mid_box_left_bt_top"
          style="
            margin: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span>部门过程设置</span>
          <el-button v-if="!unlock" @click="unlock = true" type="primary"
            >编辑图层</el-button
          >
          <div v-if="unlock">
            <el-button @click="reqData" type="primary">保存</el-button>
            <el-button @click="cancle">取消</el-button>
          </div>
        </div>
        <div class="temp-table" :style="{ height: elementHeight + 'px' }">
          <div style="border: 1px solid #d3d3d3" @click="showMessage">
            <el-table
              :class="[!unlock ? 'table-disabled' : null]"
              :data="processData"
              @cell-dblclick="showIndex1"
              @cell-click="showIndex2"
              style="width: 100%"
              border
              :header-cell-style="{
                'background-color': '#EDF0F7',
                'border-bottom': '1px solid #D3D3D3',
                'border-right': '1px solid #D3D3D3',
              }"
            >
              <el-table-column prop="processName" label="" width="150">
                <el-table-column
                  prop="processName"
                  label="过程"
                  width="150"
                ></el-table-column
              ></el-table-column>
              <el-table-column prop="" label="部门">
                <el-table-column
                  :resizable="false"
                  v-for="(item, index) in tempData"
                  :key="index"
                  :prop="item.prop"
                  :label="item.deptmentName"
                ></el-table-column>
              </el-table-column>
            </el-table>
          </div>
          <div class="mask"></div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import { get, post } from "../../api/http";

export default {
  data() {
    return {
      elementHeight: 0,
      companyData: [],
      processData: [
        // {
        //   processName: "111",
        //   id: 1,
        //   prop1: "",
        //   prop2: "",
        //   prop3: "",
        //   prop4: "",
        // },
        // {
        //   processName: "222",
        //   id: 2,
        //   prop1: "",
        //   prop2: "",
        //   prop3: "",
        //   prop4: "",
        // },
        // {
        //   processName: "333",
        //   id: 3,
        //   prop1: "",
        //   prop2: "",
        //   prop3: "",
        //   prop4: "",
        // },
      ],
      tempData: [
        // { deptmentName: "1", prop: "prop1" },
        // { deptmentName: "2", prop: "prop2" },
        // { deptmentName: "3", prop: "prop3" },
        // { deptmentName: "4", prop: "prop4" },
      ],
      defaultProps: {
        children: "companylist",
        label: "companyName",
      },
      treeItem: {},
      checkedList: [],
      tableData: [], //查询的数据
      unlock: false,
    };
  },
  mounted() {
    this.getElementHeight();
    this.getCompanyData();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 10 + 70);
      });
    },
    getCompanyData() {
      get("/api/Company/GetTree").then((res) => {
        this.companyData = res.data;
      });
    },
    getDeptData() {
      this.tempData = [];
      get("/api/Department/GetNoTree?CompId=" + this.treeItem.id).then(
        (resp) => {
          if (resp.code == 200) {
            resp.data.map((item, index) => {
              this.tempData.push({
                deptmentName: item.deptmentName,
                prop: "prop" + index,
                id: item.id,
              });
            });
            this.getProcess();
          }
        }
      );
    },
    getProcess() {
      this.processData = [];
      get("/api/Process?CompId=" + this.treeItem.id).then((resp) => {
        resp.data.map((i) => {
          this.tempData.map((e, index) => {
            i["prop" + index] = "";
          });
        });
        if (this.tableData.length != 0) {
          this.tableData.map((t) => {
            resp.data.map((r) => {
              this.tempData.map((e, index) => {
                if (t.deptId == e.id && t.processId == r.id) {
                  r["prop" + index] = t.isMain == 1 ? "▲" : "∆";
                  this.checkedList.push(t);
                }
              });
            });
          });
        }
        this.processData = resp.data;
      });
    },
    handleNodeClick(e) {
      this.treeItem = e;
      this.getTableData();
    },
    getTableData() {
      this.unlock = false;
      this.checkedList = [];
      get("/api/DeptProc?CompId=" + this.treeItem.id).then((resp) => {
        if (resp.code == 200) {
          this.tableData = resp.data;
          this.getDeptData();
        }
      });
    },
    showIndex1(row, column) {
      if (column.property == "processName") return;
      //双击
      var rowIndex = this.processData.findIndex((p) => p.id == row.id);
      var columnIndex = column.id.split("_")[column.id.split("_").length - 1];
      if (columnIndex != 1) {
        if (this.processData[rowIndex][column.property] == "") {
          this.tempData.map((item) => {
            if (item.prop == column.property) {
              this.checkedList.push({
                processId: row.id,
                deptId: item.id,
                isMain: 0,
                companyId: this.treeItem.id,
              });
            }
          });
        } else {
          //在已有实心三角形的情况下再双击换成空心三角形就修改isMian的值
          this.tempData.map((item) => {
            this.checkedList.map((e, index) => {
              if (
                item.prop == column.property &&
                row.id == e.processId &&
                item.id == e.deptId
              ) {
                this.checkedList[index].isMain = 0;
              }
            });
          });
        }
        this.processData[rowIndex][column.property] = "∆";
      }
    },
    showIndex2(row, column) {
      if (column.property == "processName") return;
      //单击
      var rowIndex = this.processData.findIndex((p) => p.id == row.id);
      var columnIndex = column.id.split("_")[column.id.split("_").length - 1];
      if (columnIndex != 1) {
        if (this.processData[rowIndex][column.property] == "") {
          this.tempData.map((item) => {
            if (item.prop == column.property) {
              this.checkedList.push({
                processId: row.id,
                deptId: item.id,
                isMain: 1, //isMain:代表黑白三角形，黑的传1  白的传0
                companyId: this.treeItem.id,
              });
            }
          });
          this.processData[rowIndex][column.property] = "▲";
        } else {
          this.tempData.map((item) => {
            this.checkedList.map((e, index) => {
              if (
                item.prop == column.property &&
                row.id == e.processId &&
                item.id == e.deptId
              ) {
                this.checkedList.splice(index, 1);
              }
            });
          });
          this.processData[rowIndex][column.property] = "";
        }
      }
    },
    reqData() {
      post(
        "/api/DeptProc?CompId=" + this.treeItem.id,
        JSON.stringify(this.checkedList)
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.getTableData();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("系统异常，请稍后再试");
        });
    },
    cancle() {
      this.getTableData();
    },
    showMessage() {
      if (!this.unlock) {
        this.$message({
          type: "warning",
          message: "图层已锁定，请点击“编辑图层”按钮进行编辑",
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "../../assets/style/Post.css";
/deep/ .temp-table {
  position: relative;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  .el-table__header {
    tr:nth-of-type(1) > th:nth-of-type(2) .cell {
      //部门
      color: #000000b9 !important;
    }
    tr:nth-of-type(2) > th:nth-of-type(1) .cell {
      //过程
      color: #000000b9 !important;
    }
  }
  .el-table__body {
    .el-table__cell {
      background-color: white !important;
      border: 1px solid #d3d3d3;
      border-left: none;
    }
    .el-table__row {
      td:nth-of-type(1) {
        background-color: #edf0f7 !important;
      }
    }
  }
  .table-disabled::after {
    position: absolute;
    width: 100%;
    height: 94%;
    z-index: 999;
    background-color: #474747;
    opacity: 0.1;
  }
}
</style>
